<template>
  <div class="main__content">
    <AddKeyUserNotification
      v-if="!isUserHasEuKey"
      class="mt-6"
      :path="getCurrentProfilePath"
    />
    <div class="table__header_bar">
      <InputSearch isFilter @detectSearchValue="searchTableHandler" />
    </div>
    <Table
      v-if="getTableOfVotingHistoryDeputyPresiding.table"
      :content="getTableOfVotingHistoryDeputyPresiding.table.items"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Table from '@/elements/Table';
import AddKeyUserNotification from '@/elements/AddKeyUserNotification';

export default {
  components: {
    Table,
    AddKeyUserNotification,
  },
  data() {
    return {};
  },

  created() {
    this.$store.dispatch('getTableOfVotingHistoryDeputyPresiding');
  },

  computed: {
    ...mapGetters([
      'getTableOfVotingHistoryDeputyPresiding',
      'isUserHasEuKey',
      'getCurrentProfilePath',
    ]),
  },

  methods: {
    tableHandler() {
      this.$store.dispatch('getTableOfVotingHistoryDeputyPresiding');
    },

    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      this.$store.dispatch('getTableOfVotingHistoryDeputyPresiding');
    },
  },
};
</script>

<style lang="sass" scoped>
.table__header_bar.input-search__form
  @include xs
    width: 300px
</style>
